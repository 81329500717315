import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Achievements from './Achievements';
import HeroSection from './HeroSection';
import Card from './Card';
import Owl from './Owl';
import Services from './Services';
import Technologies from './Technologies';
import { Helmet } from 'react-helmet';
const BlockchainDevelopmentIndex = (props) => {
    const { slug } = useParams()
    // =========================== scroll To Top default =========================
    useEffect(() => {
        props.demo('top')
    }, [slug])
    // =========================== scroll To Top default =========================
    return (
        <>
            <Helmet>
                <title>Top Blockchain Development Company – Infograins</title>
                <meta name="title" content="Blockchain Development Company in India | Blockchain Development Services - Infograins" />
                <meta name="description" content="Are you looking for Blockchain Development Company in India? Infograins provides enterprise Blockchain Solutions which help businesses to streamline operations." />
            </Helmet>
            <HeroSection />
            <Achievements />
            <Services />
            <Technologies />
            {/* <Owl /> */}
            <Card />
        </>
    )
}

export default BlockchainDevelopmentIndex