import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const ApiData = [
    {
        title: 'Blockchain Consulting',
        content: 'Our blockchain experts provide strategic guidance, helping you understand and design a blockchain network that aligns with your business requirements. With an eye on security and scalability, we ensure your blockchain implementation is optimized for growth and sustainability.'
    },
    {
        title: 'Enterprise Blockchain Development',
        content: 'We give enterprise blockchain solutions that go beyond workflow and resource optimization to help you become a game changer in your industry. To secretly communicate, speed up important procedures, and develop continuously, create a resilient ecosystem.'
    },
    {
        title: 'Custom Blockchain Development',
        content: 'We develop custom blockchain solutions to meet the unique needs of your business, ensuring that every solution integrates seamlessly with your operations. Our team delivers everything from private blockchains to decentralized applications (dApps), tailored specifically for you.'
    },
    {
        title: 'Smart Contracts',
        content: 'Infograins designs and deploys secure smart contracts that automate and streamline business processes, reducing the risk of errors and enhancing transparency. Our smart contract solutions are rigorously tested to ensure seamless operation on the blockchain.'
    },
    {
        title: 'NFT Marketplace Development',
        content: 'Capitalize on the NFT revolution with our NFT marketplace development services. We create easy-to-use and secure NFT platforms that support buying, selling, and minting of NFTs, facilitating smooth, decentralized transactions.'
    },
    {
        title: 'DApps Development',
        content: 'We specialize in building decentralized applications across various blockchain platforms, empowering your business with applications that are both secure and scalable, ensuring a user-friendly experience for your clients'
    }
]
const Achievements = () => {
    return (
        <>
            <section className='achievements-wrap'>
                <Container>
                    <div className='achievements-title'>
                        {/* <h3 className='h3_title'>{HeadData.subheading}</h3> */}
                        <h2 className='h2_title_block_chain'>Blockchain Development Services in Focus</h2>
                        {/* <p>{HeadData.description}</p> */}
                    </div>
                    <Row>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <Row>
                                {ApiData.map(({ title, content }, key) => {
                                    return <Col sm={12} md={12} lg={12} xl={12} key={key}>
                                        <div className='achievements-card'>
                                            <h5 className='h5_title'>{title}</h5>
                                            <p>{content}</p>
                                        </div>
                                    </Col>
                                })}
                            </Row>
                        </Col>
                        <Col sm={6} md={6} lg={6} xl={6}>
                            <figure className='achievements-img'>
                                <img src={'https://res.cloudinary.com/infograinsdevelop/image/upload/v1/media/blockchain_section_one/web3_vzfvlp'} alt="W3 Service" />
                            </figure>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Achievements