import React, { useState } from 'react'
import { Col, Container, Image, Row, Modal } from 'react-bootstrap'
import MainImg from '../../../assets/media/new-landing/menu image .png'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import LocationIcon from "../../../assets/media/location-icon.svg";
import CalenderIcon from "../../../assets/media/calender-icon.svg";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import ScheduleMeetingForm from '../../pages/knowMore/ScheduleMeetingForm';
import { ToastContainer } from "react-toastify";
// import SliceWallet from "../../../assets/media/slice-wallet.webp";
// import BharatNFT from "../../../assets/media/bharat-nft-marketplace.webp";
// import BharatToken from "../../../assets/media/bharat-token.webp";
export default function HeroSection() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const options = {
        margin: 30,
        responsiveClass: true,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayHoverPause: true,
        // navText: ["Prev", "Next"],provide end-to-end software solutions. Infograins
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 1,
            },
            700: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
    };
    return (
        <>
            <section className="hero-wrap">
                <div className="hero_overlay"></div>
                <div className="OwlCarousel-wrap">

                    <OwlCarousel
                        className="owl-theme hero_slider"
                        loop
                        margin={10}
                        {...options}
                    >
                       {/* <div className="item">
                            <section className='full_size_event_gitex_banner'>

                                <Container>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} className='content'>
                                            <h1>   <span><span className='color_with_chicago'>Join</span> INFOGRAINS <span className='color_with_chicago'> on their business trip to DUBAI</span> </span>  <br />
                                                
                                            </h1>
                                            <hr />
                                            <p>Get ready to explore the latest tech innovations with INFOGRAINS. Secure your meeting in advance!</p>
                                            <div className='gitex_more_buttons'>
                                                <Link target="_blank" to="https://calendly.com/infograins-software-solutions/infograins-business-trip-to-dubai-2024">
                                                    Schedule Meeting <HiOutlineArrowNarrowRight />
                                                </Link>
                                                <Link target="_blank" to="/event/gitex-global-dubai-2024">
                                                    Know more <HiOutlineArrowNarrowRight />
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>*/}
                        <div className="item">
                            <section className='main_section'>
                                <div className='bg-box'></div>
                                <div className='bg-grains'></div>
                                <Container>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} className='content'>
                                            <svg className='side' xmlns="http://www.w3.org/2000/svg" width="8" height="291" viewBox="0 0 8 291" fill="none">
                                                <path d="M1 1C5.5 44.3333 11.8 162.8 1 290" stroke="#F68633" strokeWidth="2" />
                                            </svg>

                                            <h1> <span> Delivering</span>  <br />
                                                <span className='provide'> Cutting-Edge </span> <br />
                                                <span className='it-solutions'> IT Solutions.
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="448" height="9" viewBox="0 0 448 9" fill="none">
                                                        <path d="M0.465914 3.59895C128.697 1.00317 397.622 -1.87055 447.468 7.40079" stroke="#F68633" strokeWidth="2" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="444" height="12" viewBox="0 0 444 12" fill="none">
                                                        <path d="M0.487569 2.6382
Infograins is the leading Software Development Company where we provide end-to-end solutions for software Development. Infograins specializes in delivering tailored solutions to meet the unique needs of our clients. We provide Custom Software development, Blockchain Development, Web 3 Development, Metaverse Development, Web Development and Many more. Our In-House team leverages their in-depth knowledge to develop decentralized applications, smart contracts, and Blockchain solutions.

3C138.87 1.05309 421.091 0.378426 442.92 10.3609" stroke="#F68633" strokeWidth="2" />
                                                    </svg>
                                                </span>
                                            </h1>
                                            <p>Comprehensive expertise, Cutting-edge technology, and unwavering commitment, we offer the convenience of having all your IT needs met under a single roof.</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Image src={MainImg} alt='laptop' fluid />
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                        <div className="item">
                            <section className='full_size_event_banner_usa'>

                                <Container>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} className='content'>
                                            <h1>   <span> INFOGRAINS <span className='color_with_chicago'>IS IN</span> </span>  <br />
                                                <span className='color_with_chicago'> CHICAGO                                                </span>
                                            </h1>
                                            <hr />
                                            <p>Revolutionize your business with Top Software development company in Chicago</p>
                                            <Link target="_blank" to="/event/business-trip-to-chicago">
                                                Know more <HiOutlineArrowNarrowRight />
                                            </Link>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>

                       {/* <div className="item">
                            <section className='main_section'>
                                <div className='bg-box'></div>
                                <div className='bg-grains'></div>
                                <Container>
                                    <Row>
                                        <Col xl={6} lg={6} md={6} sm={12} className='content'>
                                            <svg className='side' xmlns="http://www.w3.org/2000/svg" width="8" height="291" viewBox="0 0 8 291" fill="none">
                                                <path d="M1 1C5.5 44.3333 11.8 162.8 1 290" stroke="#F68633" strokeWidth="2" />
                                            </svg>

                                            <h1> <span> Delivering</span>  <br />
                                                <span className='provide'> Cutting-Edge </span> <br />
                                                <span className='it-solutions'> IT Solutions.
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="448" height="9" viewBox="0 0 448 9" fill="none">
                                                        <path d="M0.465914 3.59895C128.697 1.00317 397.622 -1.87055 447.468 7.40079" stroke="#F68633" strokeWidth="2" />
                                                    </svg>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="444" height="12" viewBox="0 0 444 12" fill="none">
                                                        <path d="M0.487569 2.6382
Infograins is the leading Software Development Company where we provide end-to-end solutions for software Development. Infograins specializes in delivering tailored solutions to meet the unique needs of our clients. We provide Custom Software development, Blockchain Development, Web 3 Development, Metaverse Development, Web Development and Many more. Our In-House team leverages their in-depth knowledge to develop decentralized applications, smart contracts, and Blockchain solutions.

3C138.87 1.05309 421.091 0.378426 442.92 10.3609" stroke="#F68633" strokeWidth="2" />
                                                    </svg>
                                                </span>
                                            </h1>
                                            <p>Comprehensive expertise, Cutting-edge technology, and unwavering commitment, we offer the convenience of having all your IT needs met under a single roof.</p>
                                        </Col>
                                        <Col xl={6} lg={6} md={6} sm={12}>
                                            <Image src={MainImg} alt='laptop' fluid />
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                        </div>
                         <div className="item">
                            <section className='full_size_event_gitex_banner'>

                            </section>
                        </div>  */}
                        {/* <div className="event-banner-content">
                                    <div className="au-banner-part-one">
                                        <div className="event-banner-tagline">
                                            Meet Team Infograins In USA
                                        </div>
                                        <h2>Infograins at CES 2024 </h2>
                                        <p>
                                            Meet us at CES 2024 and witness the next wave of innovation!
                                        </p>
                                        <Link target="_blank" to="/event/ces-2024">
                                            Know more <HiOutlineArrowNarrowRight />
                                        </Link>
                                    </div>
                                </div>
                                <div className="eight-banner-part-two"></div>
                                <div className="schedule-meeting-bar">
                                    <div className="event-address">
                                        <Image src={LocationIcon} alt="address-icon" fluid /> LAS VEGAS, NV
                                    </div>
                                    <div className="event-date">
                                        <Image src={CalenderIcon} alt="calender-icon" fluid />
                                        JAN 9 2024 to JAN 12 2024
                                    </div>
                                    <button onClick={handleShow}
                                        className="schedule-meeting-link"
                                    >
                                        Schedule a Meeting Now <HiOutlineArrowNarrowRight />
                                    </button>
                                </div> */}

                        {/* 10 */}
                        {/* <div className="item">
                            <div className="full-size-event-banner-au">
                                <div className="event-banner-content">
                                    <div className="au-banner-part-one">
                                        <div className="event-banner-tagline">
                                            Meet Team Infograins In Dubai
                                        </div>
                                        <h2>World Blockchain Summit</h2>
                                        <p>
                                            Schedule a meeting to know more about our blockchain
                                            services at World Blockchain Summit 2023.
                                        </p>
                                        <Link target="_blank" to="/event/world-blockchain-summit-event">
                                            Know more <HiOutlineArrowNarrowRight />
                                        </Link>
                                    </div>
                                </div>
                                <div className="eight-banner-part-two"></div>
                                <div className="schedule-meeting-bar">
                                    <div className="event-address">
                                        <Image src={LocationIcon} alt="address-icon" fluidUnity Game Development
 /> UAE
                                    </div>
                                    <div className="event-date">
                                        <Image src={CalenderIcon} alt="calender-icon" fluid />
                                        1st Nov to 2nd Nov 2023
                                    </div>
                                    <a
                                        href="https://calendly.com/infograinssoftwaresolution/dubaiexpos2023?month=2023-09"
                                        target={"_blank"}
                                        className="schedule-meeting-link"
                                    >
                                        Schedule a Meeting Now <HiOutlineArrowNarrowRight />
                                    </a>
                                </div>
                            </div>
                        </div> */}

                    </OwlCarousel>
                </div>
            </section>
            <ToastContainer />
            <Modal show={show} onHide={handleClose} centered className='event_popUp'>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule a Meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='pop_up_schedule_form'>
                        <ScheduleMeetingForm error={0} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
