import React, { useEffect, useState } from "react";
import TB_Logo from '../../../assets/images/tb_logo.svg'
import top1 from "../../../assets/images/konwMore/Group 31.png";
import top2 from "../../../assets/images/konwMore/IMAGE.png";

import top3 from "../../../assets/images/konwMore/IMAGE-1.png";
import top4 from "../../../assets/images/konwMore/IMAGE-2.png";
import top5 from "../../../assets/images/konwMore/IMAGE-3.png";
import top6 from "../../../assets/images/konwMore/IMAGE-4.png";

import asset3 from "../../../assets/images/konwMore/asset 03.png";
import asset2 from "../../../assets/images/konwMore/asset 02.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./knowMore.css";
import "react-phone-input-2/lib/bootstrap.css";
import { Helmet } from "react-helmet";
import $ from "jquery";
import { BsStarFill, } from "react-icons/bs";
import { BsLinkedin } from 'react-icons/bs'
import { BsArrowUpShort } from 'react-icons/bs'

import { FaFacebookF, FaInstagram, FaLinkedinIn, FaSkype, FaTwitter, } from "react-icons/fa";

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import KnowMoreContent from "./KnowMoreContent";

import { BiUpArrowAlt } from "react-icons/bi";
import { Container, Image, Row, Col } from "react-bootstrap";
import AjayShukla from '../../../assets/images/ajay-shukla.png'
import VipinShukla from '../../../assets/images/vipin-shukla.png'
import RahulJain from '../../../assets/images/rahul-jain.png'
import PayalPorwal from '../../../assets/images/payal-porwal.png'
import SainaJaishwal from '../../../assets/images/saina-jaishwal.png'

import ScheduleMeetingForm from "./ScheduleMeetingForm";
import Call_icon from "../../../assets/media/gif/call-icon.gif";
import { Modal } from 'react-bootstrap'
export default function KnowMore(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { pathname } = useLocation()
  const param = useParams();
  const [ed, seted] = useState(1);

  const [know, setknow] = useState({});

  const [showTopBtn, setShowTopBtn] = useState(false)
  window.addEventListener('scroll', () => {
    if (window.scrollY > 200) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  });



  const handleTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }




  const id = param.id

  const check = (id) => {
    const object = KnowMoreContent.find((obj) => obj.url == id);
    setknow(object);
  };



  useEffect(() => {
    check(id);
    props.demo("top");

  }, []);

  $("ul li div").click(function () {
    $("li div").removeClass("active2");

    $(this).addClass("active2");

    $("#shows").removeClass("hideOne");
    $(this).addClass("showOne");
  });

  // ==========================================================
  useEffect(() => {
    // setTimeout(() => {
    const scriptTag = document.createElement('script')
    scriptTag.src = "https://assets.calendly.com/assets/external/widget.js"
    document.body.appendChild(scriptTag)
    // }, 4000);
  }, [])

  console.log(know, "-----know")
  return (
    <>
      <Helmet>
        <meta name="description" content={know.Description} />
        {/* <meta name="description" content="Infograins offers a range of services including Blockchain Development, Custom Software Development, Game Development, Crypto Wallet Development, Web3 Development, Metaverse Development, NFT Development, Defi Development, Hybrid Blockchain, Wireframing and Prototyping, Object Recognitiont, IoT Dashboard and Analytics, Mobile Development, Web Development, Data Analytics, Business Intelligence, Sentimental Analysis, Salesforce Solutions, AI & ML Development, IoT,Natural Language Processing, Alexa Skills Development, Salesforce Implementation, Ecommerce, Blockchain App, Mobile App, Web App, IoT App"/> */}
        <meta name="keywords" content="Blockchain Development, Custom Software Development, Game Development, Crypto Wallet Development, Web3 Development, Metaverse Development, NFT Development, Defi Development, Hybrid Blockchain, Wireframing and Prototyping, Object Recognitiont, IoT Dashboard and Analytics, Mobile Development, Web Development, Data Analytics, Business Intelligence, Sentimental Analysis, Salesforce Solutions, AI & ML Development, IoT,Natural Language Processing, Alexa Skills Development, Salesforce Implementation, Ecommerce, Blockchain App, Mobile App, Web App, IoT App" />
        <meta property="og:title" content="Software development company chicago | Infograins Software Solutions" />
        <meta property="og:description" content="Are you looking to hire Software development company in Chicago? Infograins Software Solutions is a software development company in Chicago." />
        <meta property="og:url" content="https://www.infograins.com/software-development-company" />
        <meta property="og:image" content="https://infograins.com/static/media/logo-infograins.50a47f0d2f57d74398e3.webp" />
        <meta property="og:locale" content="en_US" />
        <title>{know.title}</title>
        <link href={know.canonical} rel="canonical" />
        <script type="application/ld+json">
          {know.eventScript}
        </script>
      </Helmet>
      <ToastContainer />
      <section>
        {/* Main Section Start */}

        <div className=" konw-main-bg" >
          {/* <header>
            <a href="tel:+9197704 77239">Contact : +91 97704 77239</a>
          </header> */}
          <div className="col-md-12 top-div col-sm-12 p-3 d-flex align-items-center justify-content-center ">
            <Link to='/'>
              <img className="top-img float-end " src={top1} alt="" />
            </Link>
          </div>

          {/* <div className="col-md-6 top-div2 col-sm-6  p-3">
              <img className="top-img float-start" src={null} alt="" />
            </div> */}
          <div className="contact_number_css ">
            <a href={`tel:${know?.phone || "tel:+9197704 77239"}`} className="call_icon"><img src={Call_icon} alt="call" loading='lazy' />Contact :  {know?.phone || +13312695159}</a>
          </div>
          <section className="hero_section_event_page">
            <Container>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <div className="heading_container">
                    <div className="heading_area_event_gitex">
                      {pathname.includes('/event/gitex-global-dubai-2024')
                        ? <>
                        <span className="">Join Infograins at GITEX Dubai 2024 – Get the IT Services for Your Business Needs</span>
                        <h2 className="">{know.heading}</h2>
                        </>
                        : <h1 className="">{know.heading}</h1>}
                      <p className="">{know?.date}</p>
                    </div>
                  </div>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <div className="event_page_lead_form">
                    <ScheduleMeetingForm error={0} />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          {/* <div className="d-flex justify-content-center text-white">
            <div className="col-md-6 know-main-text">
              <h1 className="text-center">{know.heading}</h1>
            </div>
          </div> */}

          {/* <div className="d-flex justify-content-center text-white">
            <div className="  p-3  ">
              <div className="Kown-main-p ms-auto me-auto">
                <p className="text-center  ">Meet Team Infograins</p>
              </div>
            </div>
          </div> */}

          {/* <div className="d-flex justify-content-center text-white">
            <div className="col-md-6 konw-shedu-div p-3" style={{ flexWrap: 'wrap' }}>
              <div className="d-flex justify-content-center" style={{ flexWrap: 'wrap' }}>
                {know.date ?
                  <div className="p-2 me-2 toUppercase">{know.date} </div> : ''
                }
                {
                  know.boothNum ?
                    <div className="p-2 me-2 toUppercase">{know.boothNum} </div> : ''
                }
                {
                  know.location ?
                    <div className="p-2 me-2 toUppercase">{know.location} </div> : ''
                }

                <div>
                  {
                    pathname.includes('/event/gitex-global-event') ?
                      <a href="#schedule_meeting" className="know-schedu-btn ms-2">Book a Meeting</a>
                      :
                      <button onClick={handleShow} className="know-schedu-btn ms-2">
                        Book a Meeting
                      </button>
                  }
                </div>
                <Modal show={show} onHide={handleClose} centered className='event_popUp'>
                  <Modal.Header closeButton>
                    <Modal.Title>Book a Meeting</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='pop_up_schedule_form'>
                      <ScheduleMeetingForm error={1} />
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div> */}

          <div className="container text-light pt-3 pb-5" id="new-responsive-container">

            <hr className="text-white" style={{ display: "none" }} />

            <div className='footer_address' id="footer-address-id">
              <div className='clt_and_gfr_img_wrap new-padding-responsive mt-3'>

                <div className='bottom_logos' id="bottom-logos-id">
                  <div className="clt_and_gfr_img" id="clt-and-gfr-img-id">

                    <div className="clutch-widget" id="clutch-widget-id" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="458978"></div>

                    <div className="tb_logo" id="tb-logo-id">
                      <a href='https://techbehemoths.com/company/infograins-software-solutions-pvt-ltd' target='_blank' rel='noreferrer'>
                        <img src={TB_Logo} alt="tb_logo" className='img-fluid' loading='lazy' style={{ width: "165px" }} />
                      </a>
                    </div>

                    <div className="good-firm-new">
                      <div className="goodfirm-widget" data-widget-type="goodfirms-widget-t1" data-height="198" data-company-id="7780"></div>
                    </div>

                    <div className="award-logo-div">
                      <a href='https://www.softwaresuggest.com/services/infograins-software-solutions' target='_blank'>
                        <img src='https://www.softwaresuggest.com/award_logo_service/new_award/FG-MobileApp Development-23.svg' />
                      </a>
                    </div>

                  </div>
                </div>

              </div>
            </div>

            {/* <div className="row mt-3 ">
              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex ">
                  <div>
                    <img src={top3} alt="" />
                  </div>
                  <div className="mt-2">
                    Top enterprise app development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex">
                  <div>
                    <img src={top4} alt="" />
                  </div>
                  <div className="mt-2">
                    Top Custom Software Development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div">
                <div className="d-flex">
                  <div>
                    <img src={top5} alt="" />
                  </div>
                  <div className="mt-2">
                    Top 20 e-commerce Development companies
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-6 col-xl-3 top-images-div ">
                <div className="d-flex">
                  <div>
                    <img src={top6} alt="" />
                  </div>
                  <div className="mt-2">Top mobile app Development company</div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Main Section End */}

        {/* Event / Trip Start*/}
        <div className="know-event-trip bg-dark ">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-xl-6 mt-5 mb-5 p-5" style={{ display: 'grid', placeItems: "center" }}>
                <img src={asset3} alt="" />
              </div>
              <div className="col-md-12 col-sm-12 col-xl-6 mt-5 mb-5 text-white p-5">
                <h1 className="mt-4">{know.setionSecHead}</h1>
                <p className="text-white mt-2">{know.setionSecText}</p>

                {/* <p className="text-white mt-2">
                  Connect with team Infograins at
                  <span style={{ fontWeight: "bold" }}> {know.eventName} </span>
                  and be a part of their journey toward becoming
                </p>
          
                <p className="text-white mt-2">
                  <BsStarFill /> Inspiration
                </p>
                <p className="text-white">
                  <BsStarFill /> Innovative
                </p>
                <p className="text-white">
                  <BsStarFill /> Irreplaceable
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {/* Event / Trip END*/}

        {/* About Us Start */}
        <div className="know-About-us bg-dark ">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 col-xl-6 mt-5 text-white p-5">
                <h1 className=" mt-5">About Us</h1>
                <p className="text-white mt-3">
                  Infograins has emerged as One of the leading Custom
                  Software Development Company specialized in delivering
                  tailored software solutions designed to meet the
                  unique needs and challenges of businesses across diverse
                  industries. With a relentless focus on innovation and
                  expertise in cutting-edge technologies, we empower our
                  clients to drive efficiency, enhance productivity, and
                  achieve their business objectives with precision-engineered
                  software.
                </p>
                <p className="text-white mt-3">
                  From concept to deployment, we guide you through every
                  step of the development process, ensuring transparency,
                  reliability, and quality at every stage. With a commitment
                  to excellence and a dedication to customer satisfaction,
                  we take pride in being your trusted partner on the journey
                  towards digital transformation.
                </p>
                <p className="text-white mt-3">
                  Meet us in Chicago to explore how we can help you turn your ideas into reality. From initial consultation to full-scale project execution, Infograins is here to provide top-notch IT solutions to help your business thrive.
                </p>
                {/* <p className="text-white mt-3">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Atque totam voluptatem hic repellat dolore quisquam
                    reprehenderit?
                  </p>
                  <p className="text-white mt-3">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Magni voluptatibus error ipsum? Voluptatem eveniet amet
                    corporis, doloremque vitae enim maxime. Illo id repudiandae
                    consequuntur magnam earum?
                  </p> */}
              </div>
              <div className="col-md-12 col-xl-6 mt-3 mb-5 p-5">
                <img src={asset2} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* About Us END*/}

        {/* Few Reason start  */}
        <div className="know-few-reason p-4 ">
          <div className="container">
            <h1 className="text-center p-5">
              What Sets Us Apart ?
            </h1>
            <div className="row p-4">
              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5 konw-few-cards-new">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">01.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>In-House Team of Over 150 Developers:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    Our strength lies in our dedicated team of
                    over 150 developers, each bringing their
                    expertise and skills to the table. With such
                    a robust in-house team, we have the capability
                    to tackle projects of any size and complexity,
                    ensuring high-quality deliverables every time.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5 konw-few-cards-new">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">02.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>Experienced and Skilled Professionals:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    At Infograins Software Solution, we pride ourselves
                    on having a team of experienced professionals who
                    excel in their respective fields. From software
                    architects to UI/UX designers, our team
                    members bring a wealth of knowledge and expertise
                    to every project, guaranteeing top-notch solutions
                    tailored to your needs.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5 konw-few-cards-new">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">03.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>Agile Development Process:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    We follow an Agile development methodology,
                    enabling us to adapt quickly to changing requirements
                    and deliver results efficiently. By breaking down
                    projects into manageable sprints and prioritizing
                    collaboration and flexibility, we ensure that the
                    development process remains streamlined and transparent
                    from start to finish.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4 ">
                <div className="konw-few-cards p-5 konw-few-cards-new">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">04.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>Client-Centric Service:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    Our primary focus is on understanding and meeting
                    the unique needs of our clients. We believe in collaborative
                    partnerships, working closely with our clients to gain a deep
                    understanding of their business requirements and objectives.
                    This client-centric approach allows us to develop customized
                    solutions that address specific challenges and drive business growth.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4">
                <div className="konw-few-cards p-5 konw-few-cards-id konw-few-cards-new">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">05.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>Business-Focused Approach:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    With a keen understanding of business dynamics,
                    we develop solutions that are not just technically
                    sound but also aligned with our clients' business goals.
                    Whether you're a startup looking to disrupt the market
                    or a large organization aiming to optimize operations,
                    we tailor our solutions to maximize your business potential
                    and achieve tangible results.
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xl-4 col-sm-12 mt-4 mb-4 ">
                <div className="konw-few-cards p-5  konw-few-cards-new ">
                  <div>
                    <div className="know-few-circle"> </div>
                    <div className="know-fev-span text-white fs-3 know-few-span-number">06.</div>
                    <div className="know-fev-span text-white fs-6 set-apart-bold">
                      <b>Experience Across Industries:</b>
                    </div>
                  </div>
                  <div className="p-2 know-fev-p text-white">
                    From startups to large enterprises, we have
                    a proven track record of delivering successful
                    software solutions across various industries.
                    Our versatility and adaptability enable us to
                    cater to diverse client needs, leveraging our
                    expertise to drive innovation and success in
                    every project we undertake.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Few Reason END  */}

        {/* our areas  start  */}
        <div className="know-our-areas p-2
         ">
          <div className="container">
            <h1 className="text-center p-5 know-our-h1">
              Our Areas of Expertise
            </h1>
            <div className="row   text-center">
              <div className="col-md-6 col-xl-4 ">
                <div className="know-our-divs  ">
                  <a href="https://www.infograins.com/blockchain-development" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Blockchain Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">
                      Custom Software Development
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/game-development" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Game Development</span>
                  </a>
                </div>
              </div>

              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/blockchain/crypto-wallet-development" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">
                      Crypto Wallet Development
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/blockchain/web3-development/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Web3 Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/blockchain/metaverse-development/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Metaverse Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/nft/nft-development-company" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> NFT Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/blockchain/defi-yield-farming" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Defi Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/blockchain/hybrid-blockchain/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Hybrid Blockchain</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/design/wireframing-and-prototyping/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Wireframing and Prototyping</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/ai-development/object-recognition/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> Object Recognitiont</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/iot/iot-dashboard-and-analytics/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">IoT Dashboard and Analytics</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/mobile-development/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Mobile Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/web-development/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Web Development</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/ai-development/data-analytics/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Data Analytics</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/ai-development/business-intelligence/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Business Intelligence</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/ai-development/sentiment-analysis/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Sentimental Analysis</span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-xl-4">
                <div className="know-our-divs ">
                  <a href="https://www.infograins.com/salesforce-solutions/development/" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise">Salesforce Solutions</span>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row p-3 text-center new-dev-expertise">
              <div className="col-md-6 mt-4 mb-4 p-4">
                <div className="know-our-divs mt-5">
                  <a href="https://www.infograins.com/nft/nft-development-company" target=".">
                    <span className="konw-our-span our-expertise new-event-expertise"> NFT Development </span>
                  </a>
                </div>
              </div>
              <div className="col-md-6 mt-4 mb-4 p-4">
                <div className="know-our-divs mt-5 ">
                  <a href="https://www.infograins.com/blockchain/defi-yield-farming" target=".">
                   <span className="konw-our-span our-expertise new-event-expertise">Defi Development</span>
                  </a>
                </div>
              </div>
             
            </div> */}
          </div>
        </div>
        {/* our areas  End  */}

        {/* Create Solution start  */}
        <div className="know-create-solu">
          <div className="container">
            <div className="d-flex justify-content-center text-white">
              <div className="col-md-5 p-3  ">
                <p className="fs-3 know-create-solu-heading ">
                  We Create <span className="main-color">Solutions</span> For
                  All Major Industry Segments Including
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-xl-6  ">
                <div className="row  justify-content-center text-white p-5">
                  <div className="col-sm-6 p-2">
                    <ul className=" konw-solution-list">
                      <li className="p-1" onClick={() => seted(1)}>
                        <div className="active2 light-color p-1">Business</div>
                      </li>
                      <li className="p-1" onClick={() => seted(2)}>
                        <div className="light-color p-1">Consumer</div>
                      </li>
                      <li className="p-1" onClick={() => seted(3)}>
                        <div className="light-color p-1">Finance</div>
                      </li>
                      <li className="p-1" onClick={() => seted(4)}>
                        <div className="light-color p-1">
                          Retail & Ecommerce
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(5)}>
                        <div className="light-color p-1">
                          Government & Public
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(6)} s>
                        <div className="light-color p-1">Automobile</div>
                      </li>
                      <li className="p-1" onClick={() => seted(7)}>
                        <div className="light-color p-1">Healthcare</div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 p-2 ">
                    <ul className="konw-solution-list">
                      <li className="p-1" onClick={() => seted(8)}>
                        <div className="light-color p-1">Entertainment</div>
                      </li>
                      <li className="p-1" onClick={() => seted(9)}>
                        <div className="light-color p-1">Oil, Gas & Mining</div>
                      </li>
                      <li className="p-1" onClick={() => seted(10)}>
                        <div className="light-color p-1">
                          Banking & Insurance
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(11)}>
                        <div className="light-color p-1">Educational</div>
                      </li>
                      <li className="p-1" onClick={() => seted(12)}>
                        <div className="light-color p-1">
                          Travel & Transport
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(13)}>
                        <div className="light-color p-1">
                          Logistics & Distribution
                        </div>
                      </li>
                      <li className="p-1" onClick={() => seted(14)}>
                        <div className="light-color p-1">Manufacturing</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-sm-12 col-xl-6  bg-solu-content ">
                <div className=" p-3">
                  <div className="solu-content-text   mt-5">

                    <div className="   ">
                      {ed == 1 ? (
                        <>
                          <h1 className="know-solu-content">Business</h1>
                          <p className="light-color">
                            Enterprise Mobile app Solutions, HRMS solution,
                            GST Invoice App, Taxi Booking App, Event Mangement
                            App, Cloud-Based File Storage Platform, Workflow
                            Management System,
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 2 ? (
                        <>
                          <h1 className="know-solu-content">Consumer</h1>
                          <p className="light-color">
                            City Guide App, Online Flight Ticket Booking,
                            Online Photo Sharing App, Online Mall, Instant
                            E-Gifting Platform, Online Publication System.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 3 ? (
                        <>
                          <h1 className="know-solu-content">Finance</h1>
                          <p className="light-color">
                            Stock Market Analytic Application, Social Finance
                            App, Money lending app, Risk Data Management
                            Solution, e-wallets.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 4 ? (
                        <>
                          <h1 className="know-solu-content">Retail & Ecommerce</h1>
                          <p className="light-color">
                            iBeacon App for Retail Business, Digital Photo
                            Frame App, Online Mall, Accounting Application,
                            Portal for Bill Tracking.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 5 ? (
                        <>
                          <h1 className="know-solu-content">Government & Public</h1>
                          <p className="light-color">
                            Enterprise Resource Planning, Business Process
                            reengineering, Systems integration, IT Security.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 6 ? (
                        <>
                          <h1 className="know-solu-content">Automobile</h1>
                          <p className="light-color">
                            Quality Inspection System, Enterprise Asset
                            Management, Service Parts Management, Supplier
                            Collaboration, Product Cost Analytics
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 7 ? (
                        <>
                          <h1 className="know-solu-content">Healthcare</h1>
                          <p className="light-color">
                            Health apps, fitness app, Medical Document
                            Management, Health Information Technology
                            Services, Self Healing App, Remote patient
                            Monitoring App.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 8 ? (
                        <>
                          <h1 className="know-solu-content">Entertainment</h1>
                          <p className="light-color">
                            Live Video Streaming Apps, Music apps, Magazine
                            reader app, social networking apps.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 9 ? (
                        <>
                          <h1 className="know-solu-content">Oil, Gas & Mining</h1>
                          <p className="light-color">
                            Data Quality Management Tool, Warning System,
                            Terminal Automation System.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 10 ? (
                        <>
                          <h1 className="know-solu-content">Banking & Insurance</h1>
                          <p className="light-color">
                            Identity & Access Management System, Robotic
                            Process Automation, Agile and Data Center
                            Management, Global Insurance Management System,
                            Loss Prevention Solution, Digital Asset Mangement.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 11 ? (
                        <>
                          <h1 className="know-solu-content">Educational</h1>
                          <p className="light-color">
                            Cloud-Based Education Platform, Learning
                            Management System, Online Examination System,
                            Consulting Services, Systems Integration,
                            E-learning Platform, School Management System,
                            Training and Consulting-Portal.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 12 ? (
                        <>
                          <h1 className="know-solu-content">Travel & Transport</h1>
                          <p className="light-color">
                            Online Ticket Booking App, Taxi app, vehicle
                            tracking solution
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 13 ? (
                        <>
                          <h1 className="know-solu-content">Logistics & Distribution</h1>
                          <p className="light-color">
                            Real time tracking solution, field management
                            system, Workforce Management Solution , Fleet
                            Management Solution.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      {ed == 14 ? (
                        <>
                          <h1 className="know-solu-content">Manufacturing</h1>
                          <p className="light-color">
                            Product Lifecycle Management, Enterprise Asset
                            Mangement, Warehouse Mangement, Sales & Marketing
                            Mangement, Service Parts Management,
                            Transportation Mangement.
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Create Solution start  */}

        {/* schedule  start  */}
        <div className=" konw-schedule-bg p-2 ">
          <div className="d-flex justify-content-center text-white">
            <div className="col-md-4 ">
              <h1 className="text-center mt-4 ">
                Schedule
                <span className="konw-schedule-span"> Free Consultation</span>
              </h1>
            </div>
          </div>

          <div className="container text-white form__event">
            <ScheduleMeetingForm error={1} />
          </div>
        </div>
        {/* schedule  End  */}

        {
          pathname.includes('/event/gitex-global-dubai-2024') && <>
            <div className="our_delegates">
              <Container>
                <Row className="justify-content-around">
                  <Col lg={12} className="__head">
                    <h2>Our</h2>
                    <h2>Delegates</h2>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <div className="delegate_wrapper">
                      <div className="image_wrapper">
                        <Image src={AjayShukla} fluid alt="delegate" />
                        <a target="_blank" href="https://www.linkedin.com/in/ajay-kumar-shukla-188365b/" className="linkedin_wrapper">
                          <BsLinkedin />
                          Linkedin
                          <BsArrowUpShort className="arrow" />
                        </a>
                      </div>
                      <div className="profile_details">
                        <b className="_name">Ajay Shukla</b>
                        <p className="_designation">Founder & Director</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <div className="delegate_wrapper">
                      <div className="image_wrapper">
                        <Image src={VipinShukla} fluid alt="delegate" />
                        <a href="https://www.linkedin.com/in/blockchainteam/" target="_blank" className="linkedin_wrapper">
                          <BsLinkedin />
                          Linkedin
                          <BsArrowUpShort className="arrow" />
                        </a>
                      </div>
                      <div className="profile_details">
                        <b className="_name">Vipin Shukla</b>
                        <p className="_designation">Co-Founder & Sales Director</p>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={3} md={3} sm={12}>
                    <div className="delegate_wrapper">
                      <div className="image_wrapper">
                        <Image src={RahulJain} fluid alt="delegate" />
                        <a target="_blank" href="https://www.linkedin.com/in/rahul-jain-70934331/" className="linkedin_wrapper">
                          <BsLinkedin />
                          Linkedin
                          <BsArrowUpShort className="arrow" />
                        </a>
                      </div>
                      <div className="profile_details">
                        <b className="_name">Rahul Jain</b>
                        <p className="_designation">Chief Software Officer (CSO) </p>
                      </div>
                    </div>
                  </Col> */}
                  <Col lg={3} md={3} sm={12}>
                    <div className="delegate_wrapper">
                      <div className="image_wrapper">
                        <Image src={SainaJaishwal} fluid alt="delegate" />
                        <a target="_blank" href="https://www.linkedin.com/in/saina-jaiswal-49b966200/" className="linkedin_wrapper">
                          <BsLinkedin />
                          Linkedin
                          <BsArrowUpShort className="arrow" />
                        </a>
                      </div>
                      <div className="profile_details">
                        <b className="_name">Saina Jaishwal</b>
                        <p className="_designation">key Account Manager</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} sm={12}>
                    <div className="delegate_wrapper">
                      <div className="image_wrapper">
                        <Image src={PayalPorwal} fluid alt="delegate" />
                        <a target="_blank" href="https://www.linkedin.com/in/payal-porwal/" className="linkedin_wrapper">
                          <BsLinkedin />
                          Linkedin
                          <BsArrowUpShort className="arrow" />
                        </a>
                      </div>
                      <div className="profile_details">
                        <b className="_name">Payal Porwal</b>
                        <p className="_designation">key Account Manager</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="meeting_section" id="schedule_meeting">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={12} className="__head">
                    <h2>Schedule a Meeting</h2>
                  </Col>
                  <Col lg={12}>
                    {/* <!-- Calendly inline widget begin --> */}
                    <div className="calendly-inline-widget" data-url="https://calendly.com/infograins-software-solutions/infograins-business-trip-to-dubai-2024" style={{ minWidth: "320px", height: "1000px" }}></div>
                    {/* <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script> */}
                    {/* <!-- Calendly inline widget end --> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        }

        {/* Footer Start  */}
        <div className="know-footer bg-dark light-color p-3">
          <div className="container ">
            <div className="row light-color">
              <div className="col-md-8 d-flex p-2">
                <p className="text-white">
                  Copyright © Infograins Solutions Pvt. Ltd. 2024-All rights
                  reserved.
                  <span className="main-color ms-3"><Link to="/privacy" className="main-color">Privacy Policy</Link></span>
                  <span className="main-color ms-3"><Link to="/sitemap" className="main-color">Sitemap</Link></span>
                </p>
              </div>
              <div className="col-md-4 p-2 ">
                <div className='text-end social_media_links_in_know_footer'>
                  <a href="https://www.facebook.com/infograins/" target={'_blank'} rel='noreferrer'>
                  <FaFacebookF className=" me-4" />
                  </a>
                  <a href="https://twitter.com/infograinssoft?s=20" target={'_blank'} rel='noreferrer'>
                  <FaTwitter className=" me-4" />
                  </a>
                  <a href="https://www.instagram.com/infograins/" target={'_blank'} rel='noreferrer'>
                  <FaInstagram className=" me-4" />
                  </a>
                  <a href="https://www.linkedin.com/company/infograin-software-solutions/" target={'_blank'} rel='noreferrer'>
                  <FaLinkedinIn className=" me-4" />
                  </a>
                  <a href="https://join.skype.com/invite/NqBQ11qKBCxI" target={'_blank'} rel='noreferrer'>
                  <FaSkype className=" " />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='top-btn-wrap' onClick={handleTop} style={showTopBtn === false ? { display: "none" } : { display: "flex" }}>
          <button className='btn'><BiUpArrowAlt /></button>
        </div>


        {/* Footer End  */}
      </section>
    </>
  );
}
